import React, { Component } from "react";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import axios from "axios";
import { API_ENDPOINT } from "views/Index";

import ReCAPTCHA from "react-google-recaptcha";

// components
const captchaRef = React.createRef();
export default class InterestForm extends Component {
  constructor(props) {
    super(props);
    this.handleName = this.handleName.bind(this);
    this.handleEmail = this.handleEmail.bind(this);
    this.onTypeSelected = this.onTypeSelected.bind(this);
    this.onCabinSelected = this.onCabinSelected.bind(this);
    this.onChangeReCaptcha = this.onChangeReCaptcha(this);
    this.onClick = this.onClick.bind(this);
  }
  state = {
    request: {
      name: "",
      email: "",
      type: "Participant",
      cabin: "commodor_hytt",
    },
  };

  addErrorMessage(errorMessage) {
    toast.error(errorMessage, { theme: "colored" });
  }

  addSuccessMessage(sucessMessage) {
    toast.success(sucessMessage, { theme: "colored" });
  }

  handleName(e) {
    this.setState((prevState) => ({
      ...prevState,
      request: {
        ...prevState.request,
        name: e.target.value,
      },
    }));
  }

  handleEmail(e) {
    this.setState((prevState) => ({
      ...prevState,
      request: {
        ...prevState.request,
        email: e.target.value,
      },
    }));
  }

  onTypeSelected(e) {
    this.setState((prevState) => ({
      request: {
        ...prevState.request,
        type: e.target.value,
      },
    }));
  }

  onCabinSelected(e) {
    this.setState((prevState) => ({
      request: {
        ...prevState.request,
        cabin: e.target.value,
      },
    }));
  }

  onChangeReCaptcha(value) {
    console.log(value);
    this.setState((prevState) => ({
      request: {
        ...prevState.request,
        captchaRef: value,
      },
    }));
  }

  clearRequest() {
    this.setState((prevState) => ({
      request: {
        ...prevState.request,
        name: "",
        email: "",
        type: "Participant",
        cabin: "commodor_hytt",
      },
    }));
  }

  onClick() {
    const { name, email, type, cabin } = this.state.request;

    if (!name) {
      this.addErrorMessage("Opps! Name is required.");
      return;
    }

    if (!email) {
      this.addErrorMessage("Opps! Email is required.");
      return;
    }
    const token = captchaRef.current.getValue();

    if (!token) {
      this.addErrorMessage("Opps! Please click on I'm not a robot.");
      return;
    }

    captchaRef.current.reset();

    axios
      .post(`${API_ENDPOINT}/api/show-interest?recaptchaToken=${token}`, {
        name: name,
        email: email,
        type: type,
        cabin: cabin,
      })
      .then((response) => {
        this.addSuccessMessage("You are added to interest list.");
        this.clearRequest();
      })
      .catch((response) => {
        console.log(response);
        if (response.response.data.statusCode === 400) {
          this.addErrorMessage(response.response.data.message[0]);
        } else {
          this.addErrorMessage(response.response.data.message);
        }
      });
  }

  render() {
    const { name, email, type, cabin } = this.state.request;
    return (
      <>
        <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0">
          <div className="rounded-t bg-white mb-0 px-6 py-6">
            <div className="text-center flex justify-between">
              <h6 className="text-blueGray-700 text-xl font-bold">
                Show your interest
              </h6>
              <button
                className="bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                type="button"
                onClick={this.onClick}
              >
                Signup It's Free
              </button>
            </div>
          </div>
          <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
            <form>
              <h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
                {" "}
              </h6>
              <div className="flex flex-wrap">
                <div className="w-full lg:w-6/12 px-4">
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Name
                    </label>
                    <input
                      type="text"
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      placeholder="John Doe"
                      onChange={this.handleName}
                      value={name}
                    />
                  </div>
                </div>
                <div className="w-full lg:w-6/12 px-4">
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Email address
                    </label>
                    <input
                      type="email"
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      placeholder="john.doe@example.com"
                      onChange={this.handleEmail}
                      value={email}
                    />
                  </div>
                </div>
                <div className="w-full lg:w-6/12 px-4">
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Join As
                    </label>
                    <select
                      type="text"
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      onChange={this.onTypeSelected}
                      value={type}
                    >
                      <option value="Participant" defaultValue>
                        Participant
                      </option>
                      <option value="Speaker">Speaker</option>
                      <option vlaue="Exhibitor">Exhibitor</option>
                    </select>
                  </div>
                </div>
                <div className="w-full lg:w-6/12 px-4">
                  <div className="relative w-full mb-3">
                    <label
                      className="block text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      <span className="uppercase">Cabin preference - </span>
                      <a
                        href="https://se.tallink.com/silja-serenade#tabs-content-7"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Check out at Silja line Website.
                      </a>
                    </label>
                    <select
                      type="text"
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      defaultValue="commodor_hytt"
                      value={cabin}
                      onChange={this.onCabinSelected}
                    >
                      <option value="executive_suite">
                        Executive Suite (75 m²) för 2 vuxna och 4 barn
                      </option>
                      <option value="junior_suite">
                        Junior Suite (42–49 m²) för 2 vuxna och 2 barn (0–17 år)
                        samt 1 barn (0–11 år)
                      </option>
                      <option value="commodor_hytt" defaultValue>
                        Commodore-hytt (20-25 m2) 2 vuxna och 2 barn (0–17 år)
                        samt 1 barn (0–11 år)
                      </option>
                      <option value="deluxe_hytt">
                        DeLuxe-hytt (14 m2) för 2 vuxna och 1-2 barn
                      </option>
                      <option value="familj_hytt">
                        Familjehytt (14-18 m2) för 3-6 personer, varav en i
                        sällskapet måste vara under 18 år
                      </option>
                      <option value="familj_hytt_mumin">
                        Familjehytt Mumin (14-18 m2) för 1-4 personer
                      </option>
                      <option value="a_hytt">
                        A-hytt (11 m2) för 1-4 personer
                      </option>
                      <option value="pomenade_hytt">
                        Promenade-hytt (11 m2) för 1-4 personer
                      </option>
                      <option value="b_hytt">B-hytt för 1-4 personer</option>
                      <option value="c_hytt">C-hytt för 1-4 personer</option>
                    </select>
                  </div>
                </div>
                <div className="w-full lg:w-6/12 px-4">
                  <div className="relative w-full mb-3">
                    <ReCAPTCHA
                      ref={captchaRef}
                      sitekey={process.env.REACT_APP_SITE_KEY}
                      onChange={this.onChangeReCaptcha}
                    />
                  </div>
                </div>
              </div>
            </form>
            <div className="px-4">
              <div className="text-white px-4 py-4 border-0 rounded relative mb-4 bg-lightBlue-500">
                <span className="inline-block align-middle mr-8">
                  This is just for showing interest. This does not gaurantee
                  that you will get the ticket, after signing up you will be
                  added to the interest list and we will let you know when we
                  release the tickets and have more information. The cabin
                  preference helped us to accomodate and book the cabins
                  accordingly please choose what suits you best.
                </span>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
