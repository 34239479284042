import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/styles/tailwind.css";
import Index from "views/Index.js";
import ShowInterest from "views/ShowInterest";
import PrivacyPolicy from "views/PrivacyPolicy";
import CodeOfConduct from "views/CodeOfConduct";
import ShowInterestVerification from "views/ShowInterestVerification";
import history from "helpers/history";

ReactDOM.render(
  <BrowserRouter history={history}>
    <Switch>
      {/* add routes with layouts */}
      <Route path="/show-interest" exact component={ShowInterest} />
      <Route path="/privacy-policy" exact component={PrivacyPolicy} />
      <Route path="/code-conduct" exact component={CodeOfConduct} />
      <Route path="/show-interest-verification" exact component={ShowInterestVerification} />
      <Route path="/" exact component={Index} />
      {/* add redirect for first page */}
      <Redirect from="*" to="/" />
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);
