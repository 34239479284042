import React, { Component } from "react";

import Navbar from "components/Navbars/AuthNavbar.js";
import Footer from "components/Footers/Footer.js";
import ShowInterestVerificationCard from "components/Cards/ShowInterestVerificationCard";
import headerImage from "../assets/img/header-show-interest.jpeg";
import querySearch from "stringquery";
import { API_ENDPOINT } from "views/Index";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default class ShowInterestVerification extends Component {
  constructor(props) {
    super(props);
    var queryParams = querySearch(this.props.location.search);

    axios
      .post(
        `${API_ENDPOINT}/api/show-interest-verification?token=${queryParams.token}`,
        {}
      )
      .then((response) => {
        toast.success('Congratulation! You are added to the interest list.', { theme: "colored" });
      })
      .catch((response) => {
        if (response.response.data.statusCode === 404) {
          toast.error(response.response.data.message, { theme: "colored" });
        } else {
          toast.success(response.response.data.message, { theme: "colored" });
        }
      })
      .finally((res) => {});
  }

  render() {
    return (
      <>
        <ToastContainer />
        <Navbar transparent />
        <main className="profile-page">
          <section className="relative block h-500-px">
            <div
              className="absolute top-0 w-full h-full bg-center bg-cover"
              style={{
                backgroundImage: `url(${headerImage})`,
              }}
            >
              <span
                id="blackOverlay"
                className="w-full h-full absolute opacity-50 bg-black"
              ></span>
            </div>
            <div
              className="top-auto bottom-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden h-70-px"
              style={{ transform: "translateZ(0)" }}
            >
              <svg
                className="absolute bottom-0 overflow-hidden"
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="none"
                version="1.1"
                viewBox="0 0 2560 100"
                x="0"
                y="0"
              >
                <polygon
                  className="text-blueGray-200 fill-current"
                  points="2560 0 2560 100 0 100"
                ></polygon>
              </svg>
            </div>
          </section>
          <section className="relative py-16 bg-blueGray-200">
            <div className="container mx-auto px-4">
              <div className="relative flex flex-col min-w-0 break-words mb-6 rounded-lg -mt-64">
                <ShowInterestVerificationCard />
              </div>
            </div>
          </section>
        </main>
        <Footer />
      </>
    );
  }
}
