import React from "react";

// components

export default function PrivacyPolicyCard() {
  return (
    <>
      <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0">
        <div className="rounded-t bg-white mb-0 px-6 py-6">
          <div className="text-center flex justify-between">
            <h6 className="text-blueGray-700 text-xl font-bold">
              Privacy Policy
            </h6>
          </div>
        </div>
        <div className="flex-auto px-4 py-10 pt-0">
          <h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
            {" "}
          </h6>
          <div className="text-black px-4 py-4 border-0 rounded relative ">
            <h6 className="text-xl mb-1 text-blueGray-600 font-semibold">
              About us
            </h6>
            <p className="mb-4 text-blueGray-500">
              techXcruise - Full-Stack Conference is a project of Humanbee AB. We
              arrange this Conference with our partner Silja Line, on the route
              on Stockholm-Tallin-Stockholm. We promote responsible and
              purpose-driven data collection and use. We adhere and comply with
              the relevant data protection law globally. We are compliant with
              GDPR.
            </p>
            <h4 className="text-xl mb-1 text-blueGray-600 font-semibold">
              What techXcruise collects?
            </h4>
            <p className="mb-4 text-blueGray-500">
              <p>- Your personnel information and your emails.</p>
              <p>- We share some information with our partners and sponsors</p>
              <p>- Information we collect from you at our event.</p>
              <p>
                - All the information we collect we use to improve the event for
                you and personalise according to you needs.
              </p>
            </p>
            <h4 className="text-xl mb-1 text-blueGray-600 font-semibold">
              Store and use of information
            </h4>
            <p className="mb-4 text-blueGray-500">
              <p>- To provide the best services at our event.</p>
              <p>- To arrange best speakers for you according to your needs.</p>
            </p>
            <h4 className="text-xl mb-1 text-blueGray-600 font-semibold">
              Contact information
            </h4>
            <p className="mb-4 text-blueGray-500">
              <p>Målarvägen 41</p>
              <p>19730, Bro</p>
              <p>Stockholm, Sweden.</p>
              <p>Email: <a href="mailto:info@techxcruise.com" target="_blank" rel="noreferrer">info@techxcruise.com</a></p>
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
