import React from "react";

// components

export default function CodeOfConductCard() {
  return (
    <>
      <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0">
        <div className="rounded-t bg-white mb-0 px-6 py-6">
          <div className="text-center flex justify-between">
            <h6 className="text-blueGray-700 text-xl font-bold">
              Code of Conduct
            </h6>
          </div>
        </div>
        <div className="flex-auto px-4 py-10 pt-0">
          <h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
            {" "}
          </h6>
          <div className="text-black px-4 py-4 border-0 rounded relative ">
            <h6 className="text-xl mb-1 text-blueGray-600 font-semibold">
              Agree of Code of Conduct
            </h6>
            <p className="mb-4 text-blueGray-500">
              We expect you to agree on techXcruise code of conduct before
              joining the event. we want to keep the event safe and secure for
              you to create comfortability for you and the other participants.
              We create a an open harrasment free environment for everyone
              inclusive of gender, disability and sexual orientation. We have
              zero tolerance of shaming on gender, disability, physical
              appearance, body size, age, race, nationality and even religion.
            </p>
            <h4 className="text-xl mb-1 text-blueGray-600 font-semibold">
              Harassment includes
            </h4>
            <p className="mb-4 text-blueGray-500">
              <p>- Unwelcome Sexual attention.</p>
              <p>- Misguiding or offensive comments.</p>
              <p>- Negative discussion leads to arguments or fight.</p>
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
