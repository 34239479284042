export const handleFacebookLink = () => {
  window.open(
    "https://www.facebook.com/profile.php?id=100087376449684",
    "_blank"
  );
};

export const handleInstagramLink = () => {
  window.open(" https://www.instagram.com/tech_x_cruise", "_blank");
};

export const handleTwitterLink = () => {
  window.open("https://twitter.com/CruiseTechx", "_blank");
};

export const handleLinkedInLink = () => {
  window.open("https://www.linkedin.com/company/techxcruise", "_blank");
};

export const handleArslanLinkedInLink = () => {
  window.open("https://www.linkedin.com/in/arslanmecom", "_blank");
};

export const handleSumanLinkedInLink = () => {
  window.open("https://www.linkedin.com/in/suman-nadeem-161a9a257", "_blank");
};

export const handleKalidLinkedInLink = () => {
  window.open("https://www.linkedin.com/in/askar-kalid-079a912", "_blank");
};

export const handlePeterLinkedInLink = () => {
  window.open("https://www.linkedin.com/in/peter-elfgren-1ab56a7", "_blank");
};
  


export const handleGithubLink = () => {
  window.open("https://github.com/techXcruise", "_blank");
};

export const handleYouTubeLink = () => {
  window.open(
    "https://www.youtube.com/channel/UCzlFp3bChGmSgGW3kg9kQKw",
    "_blank"
  );
};


export const handleHumanBeeLink = () => {
  window.open(
    "https://humanbee.se",
    "_blank"
  );
};
