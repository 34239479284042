import React from "react";

// components

export default function ShowInterestVerificationCard() {
  return (
    <>
      <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0">
        <div className="rounded-t bg-white mb-0 px-6 py-6">
          <div className="text-center flex justify-between">
            <h6 className="text-blueGray-700 text-xl font-bold">
              What happens now!
            </h6>
          </div>
        </div>
        <div className="flex-auto px-4 py-10 pt-0">
          <h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
            {" "}
          </h6>
          <div className="text-black px-4 py-4 border-0 rounded relative ">
            <h6 className="text-xl mb-1 text-blueGray-600 font-semibold">
              Speakers
            </h6>
            <p className="mb-4 text-blueGray-500">
              <p>- You will be notified when CFP is open. </p>
              <p>- CFP will remain open for almost a month.</p>
              <p>- Be quick to send proposals, as we will have limited spots.</p>
              <p>- You can send multiple proposals, try to be creative.</p>
              <p>- Keep an eye on social media pages, and follow them to keep updated on latest news.</p>
            </p>
            <h4 className="text-xl mb-1 text-blueGray-600 font-semibold">
              Participants
            </h4>
            <p className="mb-4 text-blueGray-500">
              <p>- You will be notified for all the updates, when the tickets will be available. </p>
              <p>- Cruise will be sailing to Tallin and you will have fantastic 36 Hours in Cruise.</p>
              <p>- There will be over 50+ sessions, including workshops, panel discussions, breakfast cafes etc.</p>
              <p>- Keep an eye on social media pages,  and follow them to keep updated on latest news.</p>
            </p>
            <h4 className="text-xl mb-1 text-blueGray-600 font-semibold">
              Exhibitors
            </h4>
            <p className="mb-4 text-blueGray-500">
              <p>- You will be notified when we start accepting sponsorships. </p>
              <p>- There will be multiple type of exhibitions plans available including, Platinum, Gold, Startup and Silver.</p>
              <p>- Keep an eye on social media pages, and follow them to keep updated on latest news.</p>
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
