/*eslint-disable*/
import React, { Component } from "react";
import { Link } from "react-router-dom";

import axios from "axios";

import IndexNavbar from "components/Navbars/IndexNavbar.js";
import Footer from "components/Footers/Footer.js";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import headerImage from "../assets/img/pattern_react.png";
import marslanImage from "../assets/img/muhammad-arslan.png";
import kalidAskarImage from "../assets/img/kalid-askar.png";
import peterElfgrenImage from "../assets/img/peter-elfgren.png";
import sumanNadeemImage from "../assets/img/suman-nadeem.png";

import {
  handleArslanLinkedInLink,
  handleSumanLinkedInLink,
  handleKalidLinkedInLink,
  handlePeterLinkedInLink
} from "../components/utils/utils";

export const API_ENDPOINT = "https://api.techxcruise.com";

export default class Index extends Component {
  constructor(props) {
    super(props);
    this.handleEmail = this.handleEmail.bind(this);
    this.onClick = this.onClick.bind(this);
  }
  state = {
    email: "",
  };

  handleEmail(e) {
    this.setState((prevState) => ({
      ...prevState,
      email: e.target.value,
    }));
  }

  addErrorMessage(errorMessage) {
    toast.error(errorMessage, { theme: "colored" });
  }

  addSuccessMessage(sucessMessage) {
    toast.success(sucessMessage, { theme: "colored" });
  }

  clearRequest() {
    this.setState((prevState) => ({
      ...prevState,
      email: "",
    }));
  }

  onClick() {
    const { email } = this.state;
    console.log(email);

    if (!email) {
      this.addErrorMessage("Opps! Email is required.");
      return;
    }

    axios
      .post(`${API_ENDPOINT}/api/news-letter`, { email: this.state.email })
      .then((response) => {
        this.addSuccessMessage("You are added to newsletter.");
      })
      .catch((response) => {
        if (response.response.data.statusCode === 400) {
          this.addErrorMessage(response.response.data.message[0]);
        } else {
          this.addErrorMessage(response.response.data.message);
        }
      })
      .finally((res) => {
        this.clearRequest();
      });
  }

  render() {
    const { email } = this.state;
    return (
      <>
        <ToastContainer />
        <IndexNavbar fixed />
        <section className="header relative pt-16 items-center flex h-screen max-h-860-px">
          <div className="container mx-auto items-center flex flex-wrap">
            <div className="w-full md:w-8/12 lg:w-6/12 xl:w-6/12 px-4">
              <div className="pt-32 sm:pt-0">
                <h1 className="font-semibold text-4xl text-blueGray-600">
                  techXcruise - Tech X Fun
                </h1>
                <h3 className="font-semibold leading-relaxed text-2xl text-lightBlue-600">
                  May 2024, Stockholm to Tallin to Stockholm.
                </h3>
                <p className="mt-4 text-lg leading-relaxed text-blueGray-500">
                  Full-Stack Conference - Learn tech with fun and family.
                  <span className="font-semibold text-lightBlue-600">
                    {" "}
                    Show your interest it's free
                  </span>{" "}
                  we will get back to you when registration is open. Either you
                  want to join as Participant, Speaker or Exhibitor, we have
                  limited spots.
                </p>
                <div className="mt-12">
                  <Link
                    className="ml-1 text-white font-bold px-6 py-4 rounded outline-none focus:outline-none mr-1 mb-1 bg-lightBlue-600 active:bg-blueGray-600 text-sm shadow hover:shadow-lg ease-linear transition-all duration-150"
                    to="/show-interest?type=participant"
                  >
                    Participant
                  </Link>
                  <Link
                    className="ml-1 text-white font-bold px-6 py-4 rounded outline-none focus:outline-none mr-1 mb-1 bg-blueGray-700 active:bg-blueGray-600 text-sm shadow hover:shadow-lg ease-linear transition-all duration-150"
                    to="/show-interest?type=speaker"
                  >
                    Speaker
                  </Link>
                  <Link
                    className="ml-1 text-white font-bold px-6 py-4 rounded outline-none focus:outline-none mr-1 mb-1 bg-blueGray-700 active:bg-blueGray-600 text-sm shadow hover:shadow-lg ease-linear transition-all duration-150"
                    to="/show-interest?type=exhibitor"
                  >
                    Exhibitor
                  </Link>
                </div>
              </div>
            </div>
          </div>

          <img
            className="absolute top-0 b-auto right-0 pt-16 sm:w-6/12 -mt-48 sm:mt-0 w-10/12 max-h-860px"
            src={headerImage}
            alt="..."
          />
        </section>

        <section className="mt-48 md:mt-40 pb-40 relative bg-blueGray-100">
          <div
            className="-mt-20 top-0 bottom-auto left-0 right-0 w-full absolute h-20"
            style={{ transform: "translateZ(0)" }}
          >
            <svg
              className="absolute bottom-0 overflow-hidden"
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon
                className="text-blueGray-100 fill-current"
                points="2560 0 2560 100 0 100"
              ></polygon>
            </svg>
          </div>
          <div className="container mx-auto">
            <div className="flex flex-wrap items-center">
              <div className="w-10/12 md:w-6/12 lg:w-4/12 px-12 md:px-4 mr-auto ml-auto -mt-32">
                <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-lightBlue-600">
                  <img
                    alt="..."
                    src="https://images.unsplash.com/photo-1498050108023-c5249f4df085?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=700&q=80"
                    className="w-full align-middle rounded-t-lg"
                  />
                  <blockquote className="relative p-8 mb-4">
                    <svg
                      preserveAspectRatio="none"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 583 95"
                      className="absolute left-0 w-full block h-95-px -top-94-px"
                    >
                      <polygon
                        points="-30,95 583,95 583,65"
                        className="text-lightBlue-600 fill-current"
                      ></polygon>
                    </svg>
                    <h4 className="text-xl font-bold text-white">
                      Tech for everyone!
                    </h4>
                    <p className="text-md font-light mt-2 text-white">
                      We put the most popular and hot tracks together, to get
                      more wider audience to create a dynamic environemnt in sea
                      journey.
                    </p>
                  </blockquote>
                </div>
              </div>

              <div className="w-full md:w-6/12 px-4">
                <div className="flex flex-wrap">
                  <div className="w-full md:w-6/12 px-4">
                    <div className="relative flex flex-col mt-4">
                      <div className="px-4 py-5 flex-auto">
                        <div className="text-blueGray-600 p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-lightBlue-200">
                          <i className="fas fa-sitemap"></i>
                        </div>
                        <h6 className="text-xl mb-1 text-blueGray-600 font-semibold">
                          Architecture
                        </h6>
                        <p className="mb-4 text-blueGray-500">
                          You wouldn’t want to jump into a project without a
                          solid plan, and software Architecture design is no
                          different. Learn Architecture from experts.
                        </p>
                      </div>
                    </div>
                    <div className="relative flex flex-col min-w-0">
                      <div className="px-4 py-5 flex-auto">
                        <div className="text-blueGray-600 p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-lightBlue-200">
                          <i className="fas fa-database"></i>
                        </div>
                        <h6 className="text-xl mb-1 text-blueGray-600 font-semibold">
                          Big Data and AI
                        </h6>
                        <p className="mb-4 text-blueGray-500">
                          AI makes big data analytics simpler by automating and
                          enhancing data preparation, data visualization,
                          predictive modeling, and other complex analytical
                          tasks.
                        </p>
                      </div>
                    </div>
                    <div className="relative flex flex-col min-w-0">
                      <div className="px-4 py-5 flex-auto">
                        <div className="text-blueGray-600 p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-lightBlue-200">
                          <i className="fas fa-shield"></i>
                        </div>
                        <h6 className="text-xl mb-1 text-blueGray-600 font-semibold">
                          Security
                        </h6>
                        <p className="mb-4 text-blueGray-500">
                          Learn the challenges of detecting and stopping cyber
                          criminals who try to elude system and network security
                          controls and be more proactive.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="w-full md:w-6/12 px-4">
                    <div className="relative flex flex-col min-w-0 mt-4">
                      <div className="px-4 py-5 flex-auto">
                        <div className="text-blueGray-600 p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-lightBlue-200">
                          <i className="fas fa-road-bridge"></i>
                        </div>
                        <h6 className="text-xl mb-1 text-blueGray-600 font-semibold">
                          Delivery & Infrastructure
                        </h6>
                        <p className="mb-4 text-blueGray-500">
                          Consider adoption of service-based or microservice
                          architectures have a wealth of information that
                          enables fast delivery.
                        </p>
                      </div>
                    </div>
                    <div className="relative flex flex-col min-w-0">
                      <div className="px-4 py-5 flex-auto">
                        <div className="text-blueGray-600 p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-lightBlue-200">
                          <i className="fa-brands fa-java"></i>
                        </div>
                        <h6 className="text-xl mb-1 text-blueGray-600 font-semibold">
                          Java
                        </h6>
                        <p className="mb-4 text-blueGray-500">
                          Java is a simple language: Java is easy to learn and
                          its syntax is clear and concise. Java is used by
                          million of developers and one of the most popular
                          programming language.
                        </p>
                      </div>
                      <div className="px-4 py-5 flex-auto">
                        <div className="text-blueGray-600 p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-lightBlue-200">
                          <i className="fa-brands fa-js"></i>
                        </div>
                        <h6 className="text-xl mb-1 text-blueGray-600 font-semibold">
                          JavaScript
                        </h6>
                        <p className="mb-4 text-blueGray-500">
                          JavaScript is a powerful programming language that can
                          add interactivity to a website. Learn popular
                          frameworks like angular, react and vuejs etc.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="relative py-20">
          <div
            className="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20 h-20"
            style={{ transform: "translateZ(0)" }}
          >
            <svg
              className="absolute bottom-0 overflow-hidden"
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon
                className="text-white fill-current"
                points="2560 0 2560 100 0 100"
              ></polygon>
            </svg>
          </div>
          <div className="container mx-auto px-4">
            <div className="items-center flex flex-wrap">
              <div className="w-full md:w-4/12 ml-auto mr-auto px-4">
                <img
                  alt="..."
                  className="max-w-full rounded-lg shadow-lg"
                  src="https://images.unsplash.com/photo-1555212697-194d092e3b8f?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=634&q=80"
                />
              </div>
              <div className="w-full md:w-5/12 ml-auto mr-auto px-4">
                <div className="md:pr-12">
                  <div className="text-lightBlue-600 p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full bg-lightBlue-300">
                    <i className="fas fa-ship text-xl"></i>
                  </div>
                  <h3 className="text-3xl font-semibold">
                    Tech cruise with family and kids
                  </h3>
                  <p className="mt-4 text-lg leading-relaxed text-blueGray-500">
                    Don't just come and learn the tech, enjoyment and spending
                    time with your family is equally important, book a ticket
                    for a conference and get them free in the cabin. We try to
                    entertain everyone.
                  </p>
                  <ul className="list-none mt-6">
                    <li className="py-2">
                      <div className="flex items-center">
                        <div>
                          <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                            <i className="fas fa-people-roof"></i>
                          </span>
                        </div>
                        <div>
                          <h4 className="text-blueGray-500">
                            Activities for family
                          </h4>
                        </div>
                      </div>
                    </li>
                    <li className="py-2">
                      <div className="flex items-center">
                        <div>
                          <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                            <i className="fas fa-children"></i>
                          </span>
                        </div>
                        <div>
                          <h4 className="text-blueGray-500">Fun for kids</h4>
                        </div>
                      </div>
                    </li>
                    <li className="py-2">
                      <div className="flex items-center">
                        <div>
                          <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                            <i className="fas fa-gamepad"></i>
                          </span>
                        </div>
                        <div>
                          <h4 className="text-blueGray-500">Gamings</h4>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="pb-20 relative block bg-lightBlue-600">
          <div
            className="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20 h-20"
            style={{ transform: "translateZ(0)" }}
          >
            <svg
              className="absolute bottom-0 overflow-hidden"
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon
                className="text-lightBlue-600 fill-current"
                points="2560 0 2560 100 0 100"
              ></polygon>
            </svg>
          </div>

          <div className="container mx-auto px-4 lg:pt-24 lg:pb-50">
            <div className="flex flex-wrap text-center justify-center">
              <div className="w-full lg:w-6/12 px-4 py-20">
                <h2 className="text-4xl font-semibold text-white">
                  Variety of sessions
                </h2>
                <p className="text-lg leading-relaxed mt-4 mb-4 text-blueGray-300">
                  We offer multiple types of sessions, that can help to learn
                  and entertain throughout the sea journey, to optimise the
                  learnings that never ends.
                </p>
              </div>
            </div>
            <div className="flex flex-wrap mt-12 justify-center">
              <div className="w-full lg:w-3/12 px-4 text-center">
                <div className="text-lightBlue-800 p-3 w-12 h-12 shadow-lg rounded-full bg-white inline-flex items-center justify-center">
                  <i className="fas fa-person-chalkboard text-xl"></i>
                </div>
                <h6 className="text-xl mt-5 font-semibold text-white">
                  Keynotes
                </h6>
                <p className="mt-2 mb-4 text-blueGray-300">
                  Openning and closing should be exotic. So we bring the best
                  keynote speakers from the world. 40 mins!
                </p>
              </div>
              <div className="w-full lg:w-3/12 px-4 text-center">
                <div className="text-lightBlue-800 p-3 w-12 h-12 shadow-lg rounded-full bg-white inline-flex items-center justify-center">
                  <i className="fas fa-file-powerpoint text-xl"></i>
                </div>
                <h5 className="text-xl mt-5 font-semibold text-white">Talks</h5>
                <p className="mt-2 mb-4 text-blueGray-300">
                  30 mins talks that will run parallel with the range of tracks.
                  Pick one of your choice and learn something new.
                </p>
              </div>
              <div className="w-full lg:w-3/12 px-4 text-center">
                <div className="text-lightBlue-800 p-3 w-12 h-12 shadow-lg rounded-full bg-white inline-flex items-center justify-center">
                  <i className="fas fa-walkie-talkie text-xl"></i>
                </div>
                <h5 className="text-xl mt-5 font-semibold text-white">
                  Lighter Talks
                </h5>
                <p className="mt-2 mb-4 text-blueGray-300">
                  10 mins quick talks, learn the best applications that are
                  around and can be helpful for your next project.
                </p>
              </div>
            </div>
            <div className="flex flex-wrap mt-12 justify-center">
              <div className="w-full lg:w-3/12 px-4 text-center">
                <div className="text-lightBlue-800 p-3 w-12 h-12 shadow-lg rounded-full bg-white inline-flex items-center justify-center">
                  <i className="fas fa-flask text-xl"></i>
                </div>
                <h6 className="text-xl mt-5 font-semibold text-white">
                  Hands-on Labs
                </h6>
                <p className="mt-2 mb-4 text-blueGray-300">
                  2 hour hands-on labs that can give you the opportuinity to get
                  your hands dirty and get expert in few hours!
                </p>
              </div>
              <div className="w-full lg:w-3/12 px-4 text-center">
                <div className="text-lightBlue-800 p-3 w-12 h-12 shadow-lg rounded-full bg-white inline-flex items-center justify-center">
                  <i className="fas fa-plane-departure text-xl"></i>
                </div>
                <h5 className="text-xl mt-5 font-semibold text-white">
                  Deep Dive
                </h5>
                <p className="mt-2 mb-4 text-blueGray-300">
                  1.5 hour deep dive that can give you the opportuinity to learn
                  the tech from experts or inventors.
                </p>
              </div>
              <div className="w-full lg:w-3/12 px-4 text-center">
                <div className="text-lightBlue-800 p-3 w-12 h-12 shadow-lg rounded-full bg-white inline-flex items-center justify-center">
                  <i className="fas fa-mug-saucer text-xl"></i>
                </div>
                <h5 className="text-xl mt-5 font-semibold text-white">
                  Breakfast Cafe
                </h5>
                <p className="mt-2 mb-4 text-blueGray-300">
                  Spend time on breakfast with new startups or see the demo of
                  something new. And get socialise with leaders.
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="pt-20 pb-48">
          <div className="container mx-auto px-4">
            <div className="flex flex-wrap justify-center text-center mb-24">
              <div className="w-full lg:w-6/12 px-4">
                <h2 className="text-4xl font-semibold">Here are our heroes</h2>
                <p className="text-lg leading-relaxed m-4 text-blueGray-500">
                  Team behind the techXcruise conference to make it reality
                </p>
              </div>
            </div>
            <div className="flex flex-wrap">
              <div className="w-full md:w-6/12 lg:w-3/12 lg:mb-0 mb-12 px-4">
                <div className="px-6">
                  <img
                    alt="..."
                    src={marslanImage}
                    className="shadow-lg rounded-full mx-auto max-w-120-px"
                  />
                  <div className="pt-6 text-center">
                    <h5 className="text-xl font-bold">Muhammad Arslan</h5>
                    <p className="mt-1 text-sm text-blueGray-400 uppercase font-semibold">
                      Founder at techXcruise a Project of Humanbee
                    </p>
                    <div className="mt-6">
                      <button
                        className="bg-lightBlue-600 text-white w-8 h-8 rounded-full outline-none focus:outline-none mr-1 mb-1"
                        type="button"
                        onClick={handleArslanLinkedInLink}
                      >
                        <i className="fab fa-linkedin"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-full md:w-6/12 lg:w-3/12 lg:mb-0 mb-12 px-4">
                <div className="px-6">
                  <img
                    alt="..."
                    src={sumanNadeemImage}
                    className="shadow-lg rounded-full mx-auto max-w-120-px"
                  />
                  <div className="pt-6 text-center">
                    <h5 className="text-xl font-bold">Suman Nadeem</h5>
                    <p className="mt-1 text-sm text-blueGray-400 uppercase font-semibold">
                      Conference Manager at techXcruise a Project of Humanbee
                    </p>
                    <div className="mt-6">
                      <button
                        className="bg-lightBlue-600 text-white w-8 h-8 rounded-full outline-none focus:outline-none mr-1 mb-1"
                        type="button"
                        onClick={handleSumanLinkedInLink}
                      >
                        <i className="fab fa-linkedin"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-full md:w-6/12 lg:w-3/12 lg:mb-0 mb-12 px-4">
                <div className="px-6">
                  <img
                    alt="..."
                    src={kalidAskarImage}
                    className="shadow-lg rounded-full mx-auto max-w-120-px"
                  />
                  <div className="pt-6 text-center">
                    <h5 className="text-xl font-bold">Kalid Askar (Advisor)</h5>
                    <p className="mt-1 text-sm text-blueGray-400 uppercase font-semibold">
                      Developer Experience Product Director at Visa
                    </p>
                    <div className="mt-6">
                      <button
                        className="bg-lightBlue-600 text-white w-8 h-8 rounded-full outline-none focus:outline-none mr-1 mb-1"
                        type="button"
                        onClick={handleKalidLinkedInLink}
                      >
                        <i className="fab fa-linkedin"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-full md:w-6/12 lg:w-3/12 lg:mb-0 mb-12 px-4">
                <div className="px-6">
                  <img
                    alt="..."
                    src={peterElfgrenImage}
                    className="shadow-lg rounded-full mx-auto max-w-120-px"
                  />
                  <div className="pt-6 text-center">
                    <h5 className="text-xl font-bold">Peter Elfgren (Advisor)</h5>
                    <p className="mt-1 text-sm text-blueGray-400 uppercase font-semibold">
                      Head Of Account Management at BoaCompra
                    </p>
                    <div className="mt-6">
                      <button
                        className="bg-lightBlue-600 text-white w-8 h-8 rounded-full outline-none focus:outline-none mr-1 mb-1"
                        type="button"
                        onClick={handlePeterLinkedInLink}
                      >
                        <i className="fab fa-linkedin"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="py-20 bg-blueGray-800 overflow-hidden">
          <div className="container mx-auto pb-64">
            <div className="flex flex-wrap justify-center">
              <div className="w-full md:w-5/12 px-12 md:px-4 ml-auto mr-auto md:mt-64">
                <div className="text-blueGray-800 p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full bg-white">
                  <i className="fas fa-book-open text-xl"></i>
                </div>
                <h3 className="text-3xl mb-2 font-semibold leading-normal text-white">
                  One more thing...
                </h3>
                <p className="text-lg font-light leading-relaxed mt-4 mb-4 text-white">
                  <b>Agile and Tech leaders,</b> are equally importent, we have
                  not forgotton you. We have seperate track for you.
                </p>

                <p className="text-lg font-light leading-relaxed mt-4 mb-4 text-white">
                  We bring the best speakers in the world, we expect to have 50+
                  sessions in 36 hours, with special breakfast, cafe and panel
                  discussions.
                </p>

                <p className="text-2xl font-light leading-relaxed mt-4 mb-4 text-white">
                  Are we thinking different? And more importantly, Are we ready
                  to change the world?
                </p>
                <Link
                  className="mt-4 inline-block text-white font-bold px-6 py-4 rounded outline-none focus:outline-none mr-1 mb-1 bg-blueGray-700 active:bg-blueGray-600 uppercase text-sm shadow hover:shadow-lg"
                  to="/show-interest"
                >
                  Signup It's Free
                </Link>
              </div>
            </div>
          </div>
        </section>

        <section className="pb-16 bg-blueGray-200 relative pt-32">
          <div
            className="-mt-20 top-0 bottom-auto left-0 right-0 w-full absolute h-20"
            style={{ transform: "translateZ(0)" }}
          >
            <svg
              className="absolute bottom-0 overflow-hidden"
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon
                className="text-blueGray-200 fill-current"
                points="2560 0 2560 100 0 100"
              ></polygon>
            </svg>
          </div>

          <div className="container mx-auto">
            <div className="flex flex-wrap justify-center bg-white shadow-xl rounded-lg -mt-64 py-16 px-12 relative z-10">
              <div className="w-full text-center lg:w-8/12">
                <p className="text-4xl text-center">
                  <span role="img" aria-label="love">
                    😍
                  </span>
                </p>
                <h3 className="font-semibold text-3xl">
                  Do you want to keep updated?
                </h3>
                <p className="text-blueGray-500 text-lg leading-relaxed mt-4 mb-4">
                  Cause if you do, you will know latest update, when we release
                  the tickets and other exiciting news!
                </p>
                <div className="flex flex-wrap">
                  <div className="w-full lg:w-12/12 px-4">
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-password"
                      ></label>
                      <input
                        type="text"
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        value={email}
                        onChange={this.handleEmail}
                        placeholder="john.doe@example.com"
                      />
                    </div>
                  </div>
                </div>
                <div className="sm:block flex flex-col mt-10">
                  <button
                    type="button"
                    className="get-started text-white font-bold px-6 py-4 rounded outline-none focus:outline-none mr-1 mb-2 bg-lightBlue-600 active:bg-lightBlue-700 text-sm shadow hover:shadow-lg ease-linear transition-all duration-150"
                    onClick={this.onClick}
                  >
                    Signup for Newsletter
                  </button>
                </div>
                <div className="text-center mt-16"></div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </>
    );
  }
}
